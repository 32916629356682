/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 15번째 로그에서는Seocho.rb 첫 번째 모임, AWS 람다를 사용한 이미지 변환, 안전한 패스워드 관리, 1Password 등에   대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.patreon.com/stdoutfm"
  }, "Patreon: stdout.fm 정기 후원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/183"
  }, "Seocho.rb 첫 번째 모임: 서버리스 루비 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sites.google.com/site/anpshare/anpedia/altlang"
  }, "대안언어축제 - anpshare")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://cashnote.kr/"
  }, "캐시노트 - 가장 쉬운 매출관리")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://site.cashslide.co.kr/"
  }, "캐시슬라이드 - 모바일 혜택의 시작")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://programmers.co.kr/"
  }, "프로그래머스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/John_Grib/status/1089676652156661760"
  }, "기계인간 on Twitter: \"판교에서 본 프로그래머스 구인 광고. @codingwarrior_… \"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.lookpin.co.kr/"
  }, "룩핀")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn/lambda-edge%EB%A1%9C-%EA%B5%AC%ED%98%84%ED%95%98%EB%8A%94-on-the-fly-%EC%9D%B4%EB%AF%B8%EC%A7%80-%EB%A6%AC%EC%82%AC%EC%9D%B4%EC%A7%95-f4e5052d49f3"
  }, "AWS Lambda@Edge에서 실시간 이미지 리사이즈 & WebP 형식으로 변환 – 당근마켓 팀블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/lambda/latest/dg/lambda-edge.html"
  }, "Lambda@Edge - AWS Lambda")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/why-should-i-use-docker-container"
  }, "왜 굳이 도커(컨테이너)를 써야 하나요? - 컨테이너를 사용해야 하는 이유 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/daangn/aws-lambda%EB%A5%BC-%EC%9D%B4%EC%9A%A9%ED%95%9C-%EC%9D%B4%EB%AF%B8%EC%A7%80-%EC%8D%B8%EB%84%A4%EC%9D%BC-%EC%83%9D%EC%84%B1-%EA%B0%9C%EB%B0%9C-%ED%9B%84%EA%B8%B0-acc278d49980"
  }, "AWS Lambda를 이용한 이미지 썸네일 생성 개발 후기 – 당근마켓 팀블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://engineering.vcnc.co.kr/2016/05/ondemand-image-resizing/"
  }, "서버 비용을 70%나 줄인 온디맨드 리사이징 이야기 - VCNC Engineering Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/WebP"
  }, "WebP - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://summerofcode.withgoogle.com/"
  }, "Home | Google Summer of Code")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zzerjae.github.io/"
  }, "ZZERJAE – Devlog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://goree.io/events/3"
  }, "Planet Hackathon 2018 by GDG x 9XD  - Goree")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://hackerx.org/"
  }, "HackerX")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/ko/docs/Web/HTTP/Headers/Accept"
  }, "Accept HTTP 요청 헤더 | MDN")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.google.com/forms/d/e/1FAIpQLSdDQWz_f3GrgJafrks8P04H0NqokdeS53Da1cMSee02xeavBQ/viewform"
  }, "AWS Summit 2019 커뮤니티 트랙 발표 신청")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.teamsid.com/100-worst-passwords-top-50/"
  }, "The Worst Passwords of 2018 100-50 | SplashData")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://xkcd.com/936/"
  }, "xkcd: Password Strength")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://1password.com/ko/"
  }, "가장 안전한 비밀번호 관리 솔루션 | 1Password")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nakedsecurity.sophos.com/2016/08/18/nists-new-password-rules-what-you-need-to-know/"
  }, "NIST’s new password rules – what you need to know – Naked Security")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Password_strength"
  }, "Password strength - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://1password.com/ko/teams/"
  }, "가장 안전한 팀용 비밀번호 관리 솔루션 | 1Password")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bgr.com/2018/07/10/apple-1password-acquisition-deal/"
  }, "Exclusive: Apple to deploy 1Password to all 123,000 employees, acquisition talks underway – BGR")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gori.me/apple/apple-news/108218"
  }, "(일본어) Apple은 패스워드 관리 애플리케이션 1Password를 전 사원에게 배포했나 | gori.me")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://9to5mac.com/2018/08/21/hands-on-password-autofill-ios-12-1password/"
  }, "Hands-on with 1Password and iOS 12's Password AutoFill feature - 9to5Mac")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://signup.cj.com/member/signup/publisher/?cid=5140517#/branded?_k=6kz3sz"
  }, "Publisher Sign Up | CJ Affiliate by Conversant (Formerly Commission Junction)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lesspass.com/#/"
  }, "LessPass")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://watchtower.1password.com/"
  }, "1Password Watchtower")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.newsprime.co.kr/news/article.html?no=403295"
  }, "범죄 악용 '알패스' 결국 서비스 종료…이스트소프트 \"보안이슈 때문 아냐\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://techcrunch.com/2018/05/03/twitter-password-bug/"
  }, "You should change your Twitter password right now | TechCrunch")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr/HT207054"
  }, "Mac에서 Touch ID 사용하기 - Apple 지원")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://support.apple.com/ko-kr/HT204837"
  }, "Mac에서 FileVault를 사용하여 시동 디스크 암호화하기 - Apple 지원")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
